import * as S from "./style";
import registerBg from "../../assets/images/register_bg.webp";
import React from "react";
import { Button, Form, Input, message } from "antd";
import { register } from "api/register";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openLoading, closeLoading } from "features/loading/loadingSlice";
import { SITE_NAME } from "constant/configs";

function Register() {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleRegister = async (values: any) => {
    dispatch(openLoading());
    try {
      const resp: any = await register({
        uid: values.uid,
        name: values.name,
        password: values.password,
        bank_name: values.bank_name,
        account_name: values.account_name,
        account_number: values.account_number,
        // phone: values.phone,
      });
      if (resp?.result) {
        history.push({
          pathname: "/verify-phone-number",
          state: resp?.data?.id,
        });
      }
      messageApi.open({
        type: "error",
        content: resp?.error?.message || "오류입니다",
      });
      dispatch(closeLoading());
    } catch (error) {
      console.log("handleRegister error", error);
      dispatch(closeLoading());
    }
  };

  return (
    <React.Fragment>
      {contextHolder}
      <S.Wrapper>
        <img className="banner-bg" src={registerBg} alt="bg" />
        <div className="page-content">
          <div className="page-container">
            <div className="heading-text heading-section">
              <h2>회원가입</h2>
            </div>
            <S.Content>
              <div className="custom-form-antd">
                <Form
                  form={form}
                  name="control-hooks"
                  onFinish={handleRegister}
                  layout="vertical"
                >
                  <Form.Item style={{ margin: 0 }}>
                    <Form.Item
                      name="uid"
                      label="아이디"
                      rules={[
                        { required: true, message: "정보를 입력해주세요." },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                      }}
                    >
                      <Input style={{ height: 50 }} />
                    </Form.Item>
                    <Form.Item
                      name="name"
                      label="이름"
                      rules={[
                        { required: true, message: "정보를 입력해주세요." },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                        marginLeft: 16,
                      }}
                    >
                      <Input style={{ height: 50 }} />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item style={{ margin: 0 }}>
                    <Form.Item
                      name="password"
                      label="비밀번호"
                      rules={[
                        { required: true, message: "정보를 입력해주세요." },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                      }}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      name="confirmPassword"
                      label="비밀번호 재확인"
                      dependencies={["password"]}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "정보를 입력해주세요.",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error("비밀번호가 일치하지 않습니다.")
                            );
                          },
                        }),
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                        marginLeft: 16,
                      }}
                    >
                      <Input.Password />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item
                    name="bank_name"
                    label="계좌은행"
                    rules={[
                      { required: true, message: "정보를 입력해주세요." },
                    ]}
                  >
                    <Input style={{ height: 50 }} />
                  </Form.Item>
                  <Form.Item
                    name="account_number"
                    label="계좌번호"
                    rules={[
                      { required: true, message: "정보를 입력해주세요." },
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            return Promise.reject();
                          }
                          if (isNaN(value)) {
                            return Promise.reject("숫자를 입력해주세요.");
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input style={{ height: 50 }} />
                  </Form.Item>
                  <Form.Item
                    name="account_name"
                    label="계좌주명"
                    rules={[
                      { required: true, message: "정보를 입력해주세요." },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("name") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("이름과 계좌주명이 일치해야 합니다.")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input style={{ height: 50 }} />
                  </Form.Item>
                  <p
                    style={{ marginTop: 20, fontSize: 14, lineHeight: "25px" }}
                  >
                    {SITE_NAME}의{" "}
                    <Link
                      to="/terms-of-use"
                      style={{ color: "#6091FF", textDecoration: "underline" }}
                    >
                      이용약관
                    </Link>
                    ,
                    <Link
                      to="/privacy-policy"
                      style={{ color: "#6091FF", textDecoration: "underline" }}
                    >
                      개인정보 처리방침
                    </Link>
                    을 확인하시고 내용에 동의하실 경우에만 회원가입을
                    진행해주십시오.
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 64,
                    }}
                  >
                    <Button
                      htmlType="submit"
                      size="large"
                      className="btn btn-v01 btn-rounded"
                      style={{ height: 50, width: 315, fontSize: 18 }}
                    >
                      다음
                    </Button>
                  </div>
                </Form>
              </div>
            </S.Content>
          </div>
        </div>
      </S.Wrapper>
    </React.Fragment>
  );
}

export default Register;
